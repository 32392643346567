import {createApi, fetchBaseQuery,} from '@reduxjs/toolkit/dist/query/react';
import type {FetchBaseQueryError} from '@reduxjs/toolkit/query'
import {Auth} from 'aws-amplify';
import {ISite} from "../../types";
import {QueryReturnValue} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {store} from "../store";


import {io} from "socket.io-client";
//
// const socket = io("https://sloth-config-api.dev.gbads.net" , {
//     path : "/socket",
// })

// Function to get the base URL from the Redux store
const getBaseUrl = () => {
    const state = store.getState();
    return state.envConfig.SLOTH_API_URL;
};


type ISites = {
    results: ISite[]
    filter?: any
    meta: any
}

// API Doc: https://sloth-config-api.dev.gbads.net/api


export const slothAPI = createApi({
    reducerPath: 'slothAPI',
    baseQuery: async (args, api, extraOptions) => {
        // Get the base URL from the Redux store
        const baseUrl = getBaseUrl();

        // Fetch the token (example using AWS Amplify)
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();

        // Use fetchBaseQuery to proceed with the actual query
        const rawBaseQuery = fetchBaseQuery({
            baseUrl,
            prepareHeaders: (headers) => {
                headers.set('Authorization', `${token}`);
                return headers;
            },
        });

        return rawBaseQuery(args, api, extraOptions);
    },

    tagTypes: ['Publishers', 'Sites', 'Config', 'Presets', 'History', 'BuilderConfig'],
    endpoints: (builder) => ({
        getBuilderConfig: builder.query<any, void>({
            query: () => '/config/builderConfig',
            transformResponse: (response: any) => {
                return response.results?.[0] || {};
            },
            providesTags: ['BuilderConfig'],
            transformErrorResponse: (response: any, meta, arg) =>
                response.data.message[0]
        }),

        getPublishers: builder.query<ISites, void>({
            query: () => '/publishers',
            transformErrorResponse: (response: any, meta, arg) =>
                response.data.message[0]
        }),

        addPublisher: builder.mutation({
            query: (queryArgs) => ({
                url: '/publishers',
                method: 'POST',
                body: {
                    "name": queryArgs.name + ".publisher.js",
                    "description": queryArgs.description,
                    "url": queryArgs.url
                }
            }),
            async onQueryStarted(queryArgs, {dispatch, queryFulfilled}) {
                const {data: updatedPost} = await queryFulfilled
                dispatch(
                    slothAPI.util.updateQueryData('getPublishers', undefined, (draft) => {
                        Array.isArray(draft?.results) && draft.results.push(updatedPost)
                    })
                )
            }
        }),

        getStats: builder.query<any, string>({
            query: (id) => `/stats/${id}`,
            transformErrorResponse: (response: any, meta, arg) =>
                response.data.message[0]
        }),

        getSites: builder.query<ISites, void>({
            query: () => '/sites',
            transformErrorResponse: (response: any, meta, arg) =>
                response.data.message[0]
        }),
        addNewSite: builder.mutation({
            query: (queryArgs) => ({
                url: '/sites',
                method: 'POST',
                body: {
                    "name": queryArgs.siteName + ".config.js",
                    "url": queryArgs.url,
                    "publisher": queryArgs.publisherName,
                    "displayName": queryArgs.displayName
                }
            }),
            async onQueryStarted(queryArgs, {dispatch, queryFulfilled}) {
                const {data: updatedPost} = await queryFulfilled
                dispatch(
                    slothAPI.util.updateQueryData('getSites', undefined, (draft) => {
                        Array.isArray(draft?.results) && draft.results.push(updatedPost)
                    })
                )
            }
        }),

        putSiteData: builder.mutation({
            query: (queryArgs) => ({
                    url: `/sites/config/${queryArgs.id}`,
                    method: 'PUT',
                    body: queryArgs.data
                }
            ),
            async onQueryStarted(queryArgs, {dispatch, queryFulfilled}) {
                const {data: updatedPost} = await queryFulfilled
                dispatch(
                    slothAPI.util.updateQueryData('getSites', undefined, (draft) => {
                        //find object inside draft that has same name as queryArgs.id and update it
                        const index = draft.results.findIndex((obj) => obj.name === queryArgs.id);

                        draft.results[index] = updatedPost;
                    })
                )
            }
        },),

        getSiteConfig: builder.query({
            query(queryArgs) {
                return `sites/config/${queryArgs.id}`;
            },
            transformResponse: (response: any) => {
                return response.results[0] || {}
            }
        }),

        //TODO: use only one put site config

        putSiteConfig: builder.mutation({
            query: (queryArgs) => ({
                url: `sites/config/${queryArgs.id}`,
                method: 'PUT',
                body: queryArgs.data
            }),
            invalidatesTags: ["History"],
            async onQueryStarted(queryArgs, {dispatch, queryFulfilled}) {
                const {data: updatedPost} = await queryFulfilled
                dispatch(
                    slothAPI.util.updateQueryData('getSiteConfig', {id: queryArgs.id}, (draft) => {
                        draft = updatedPost
                        return draft;
                    })
                )
            }
        }),

        getConfigHistory: builder.query({
            query(queryArgs) {
                return `history/${queryArgs.service}/${queryArgs.id}`;
            },
            providesTags: ["History"]
        }),


        getConfigCommit: builder.query({
            query(queryArgs) {
                return `history/${queryArgs.service}/${queryArgs.id}/${queryArgs.commitId}`;
            },
            transformResponse: (response: any) => response.results[0] || {},
        }),


        getPresets: builder.query<ISites, void>({
                query: () => '/presets',

                transformErrorResponse: (response: any, meta, arg) =>
                    response.data.message[0],
            }
        ),

        addPreset: builder.mutation({
            query: (queryArgs) => ({
                url: '/presets',
                method: 'POST',
                body: {
                    "name": queryArgs.name + ".preset.js",
                    "description": queryArgs.description
                }
            }),
            async onQueryStarted(queryArgs, {dispatch, queryFulfilled}) {

                const {data: updatedPost} = await queryFulfilled

                dispatch(
                    slothAPI.util.updateQueryData('getPresets', undefined, (draft) => {
                        Array.isArray(draft?.results) && draft.results.push(updatedPost)
                    })
                )
            }
        }),

        deletePreset: builder.mutation({
            query: (queryArgs) => ({
                url: `/presets/${queryArgs.id}`,
                method: 'DELETE',
            }),
            async onQueryStarted(queryArgs, {dispatch, queryFulfilled}) {
                //TODO: delete is not updated
                dispatch(
                    slothAPI.util.updateQueryData('getPresets', undefined, (draft) => {
                        const nameToRemove = queryArgs.id; // Get the name to remove

                        const indexToRemove = draft.results.findIndex((element) => element.name === nameToRemove);

                        if (indexToRemove !== -1) {
                            draft.results.splice(indexToRemove, 1);
                        }
                    })
                )
            }
        },),

        getPresetConfig: builder.query({
            query(queryArgs) {
                return `presets/${queryArgs.id}`;
            },
            transformResponse: (response: any) => response.results[0] || {}
        }),

        getPresetConfigs: builder.query<any[], string[] | undefined>({
            queryFn: async (presets, api, extraOptions, baseQuery) => {
                if (presets) {
                    const responses: any[] = await Promise.all(
                        presets.map((preset) => baseQuery(`presets/${preset}`))
                    )
                    const data: any = responses.map((response) => response.data.results[0]);
                    return {data} as QueryReturnValue<any[], FetchBaseQueryError, unknown>; // Adjust the return type
                } else {
                    return {data: []} as QueryReturnValue<any[], FetchBaseQueryError, unknown>; // Return an empty array if there are no presets
                }
            },
        }),

        putPresetConfig: builder.mutation({
            query: (queryArgs) => ({
                url: `presets/${queryArgs.id}`,
                method: 'PUT',
                body: queryArgs.data
            }),
            async onQueryStarted(queryArgs, {dispatch, queryFulfilled}) {
                const {data: updatedPost} = await queryFulfilled
                dispatch(
                    slothAPI.util.updateQueryData('getPresetConfig', {id: queryArgs.id}, (draft) => {
                        draft = updatedPost
                        return draft;
                    })
                )
            }
        }),
        gbucketExtractor: builder.mutation({
            query: (queryArgs) => ({
                url: '/extractor',
                method: 'POST',
                body: {
                    "url": queryArgs.urls
                }
            }),
            async onQueryStarted(queryArgs, {dispatch, queryFulfilled}) {
                try {
                    const {data: updatedPost} = await queryFulfilled;

                    if (queryArgs.presetId) {
                        await dispatch(slothAPI.endpoints.getPresetConfig.initiate({id: queryArgs.presetId})).unwrap();
                        dispatch(
                            slothAPI.util.updateQueryData('getPresetConfig', {id: queryArgs.presetId}, (draft) => {
                                draft.gbucket = updatedPost;
                                return draft;
                            })
                        );
                    } else {
                        await dispatch(slothAPI.endpoints.getSiteConfig.initiate({id: queryArgs.id})).unwrap();
                        dispatch(
                            slothAPI.util.updateQueryData('getSiteConfig', {id: queryArgs.id}, (draft) => {
                                draft.gbucket = updatedPost;
                                return draft;
                            })
                        );
                    }
                } catch (error) {
                    console.error('Error in gbucketExtractor:', error);
                }
            }
        }),

        syncGbucket: builder.mutation({
            query: () => ({
                url: '/gbucket/sync',
                method: 'POST',
            }),
            transformErrorResponse: (response: any) => {
                return response.data.message;
            },
        }),

    }),
})

export const {
    useAddPublisherMutation,
    useGetPublishersQuery,
    useGetStatsQuery,
    useGetSitesQuery,
    useGetSiteConfigQuery,
    useAddNewSiteMutation,
    usePutSiteConfigMutation,
    useGetConfigHistoryQuery,
    useGetConfigCommitQuery,
    useGetPresetsQuery,
    useAddPresetMutation,
    useDeletePresetMutation,
    useGetPresetConfigQuery,
    useGetPresetConfigsQuery,
    usePutPresetConfigMutation,
    usePutSiteDataMutation,
    useGbucketExtractorMutation,
    useSyncGbucketMutation,
    useGetBuilderConfigQuery
} = slothAPI;


// export const setupSocketListeners = (dispatch : any) => {
//     // Add your socket event listeners here
//     // Example:
//     socket.on("sites", (data) => {
//         console.log(data)
//         dispatch(slothAPI.endpoints.getSites.initiate(undefined, {forceRefetch: true}));
//     });
// };