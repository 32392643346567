import React, { ReactNode } from 'react';
import NoMatch from '../components/NoMatch';
import { useEnvConfig } from '../redux/hooks';

interface UnderConstructionWrapperProps {
    children: ReactNode;
}

const UnderConstructionWrapper: React.FC<UnderConstructionWrapperProps> = ({ children }) => {
    const isUnderConstruction = useEnvConfig('UNDER_CONSTRUCTION');

    if (isUnderConstruction) {
        return <NoMatch errorMessage="Site Under Maintenance" />;
    }

    return <>{children}</>;
};

export default UnderConstructionWrapper;